import {
	IAppWillLoadPageHandler,
	ILogger,
	IPageWillMountHandler,
	LifeCycle,
	LoggerSymbol,
} from '@wix/thunderbolt-symbols'
import { multi, withDependencies } from '@wix/thunderbolt-ioc'
import { taskify } from '@wix/thunderbolt-commons'
import { PageProviderSymbol } from './symbols'
import { IPageInitializer, IPageProvider } from './types'

export const PageInitializer = withDependencies(
	[multi(LifeCycle.AppWillLoadPageHandler), PageProviderSymbol, LoggerSymbol],
	(
		appWillLoadPageHandlers: Array<IAppWillLoadPageHandler>,
		pageProvider: IPageProvider,
		logger: ILogger
	): IPageInitializer => ({
		initPage: async ({ pageId, contextId }) => {
			logger.phaseStarted('init_page')
			const pageReflectorPromise = pageProvider(contextId, pageId)
			const pageWillMount = taskify(async () => {
				const pageReflector = await pageReflectorPromise
				const pageWillMountHandlers = pageReflector.getAllImplementersOf<IPageWillMountHandler>(
					LifeCycle.PageWillMountHandler
				)
				await Promise.all(pageWillMountHandlers.map((handler) => taskify(() => handler.pageWillMount(pageId))))
			})

			await Promise.all([
				pageWillMount,
				...appWillLoadPageHandlers.map((handler) =>
					taskify(() => handler.appWillLoadPage({ pageId, contextId }))
				),
			])
		},
	})
)
